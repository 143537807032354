import React, {useRef, useState, useEffect} from "react";
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons"
import {faUser} from "@fortawesome/free-regular-svg-icons"
import axios from 'axios';
import OtpInput from 'react-otp-input';
import {useCookies} from "react-cookie";
import './css/fonts.css';
import './css/style.css';
import Checkbox from "./components/CheckBox";
import SpinCircle from "./components/SpinCircle";
import QRCode from 'qrcode.react';
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GuardianAPI from './components/GuardianAPI'

import './common/i18n';
import i18next from "i18next";
import {FormControl, makeStyles, MenuItem, Select} from "@material-ui/core";

// 실제 운영
const CLIENT_KEY = "d227e269a29d4b44b30e28a9c296ba2f";
// const MAIL_HOST = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://192.168.0.55:8659" : "https://group.fnsvalue.co.kr";
const MAIL_HOST = "https://group.fnsvalue.co.kr";
// const MAIL_HOST = "https://group.fnsitech.com";

// const CLIENT_KEY = "1daec78593a643e6b53ce9803ded5916";
// const MAIL_HOST = null;

const FullView = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: center / cover no-repeat url("/img/GCCS_background.jpg");
  // background: center / cover no-repeat url("/img/itech/itech_bg.jpg");
`

const LoginContainer = styled(Box)`
  display: flex;
`

const LoginImageBox = styled(Box)`
  position: relative;
  display: flex;
  width: 920px;
  height: 760px;
  background: center / cover no-repeat url("/img/img.jpg");
  // background: center / cover no-repeat url("/img/itech/itech_img.jpg");
  @media (max-width: 1440px) {
    display: none;
  }
`
const LoginAnimated = styled(Box)`
  width: 148px;
  height:97px;
  position: absolute;
  top:245px;
  left:330px;
  background: center / cover no-repeat url("/img/GCCS_animated.gif");
`

const LoginBox = styled(Box)`
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 350px;
  height: 620px;
  padding: 50px 65px 90px 65px;
  @media( max-width: 768px){
    padding: 40px;
    border-radius: 5px;
    width: initial;
    height: initial;
    min-height:480px;
  }
`
const LoginLabel = styled.label`
    font-family: 'Noto Sans CJK KR';
    font-weight: 350;
    font-size:1.563rem;
    color:#555;
    line-height: 2.375rem;
    letter-spacing: -0.04rem;
`

const LoginText = styled.label`
    font-family: 'Noto Sans CJK KR';
    font-weight: 900;
    font-size:2.375rem;
    color:#0258CC;
    line-height: 3.250rem;
    letter-spacing: -0.04rem;
    @media( max-width: 768px){
      line-height: 1.875rem;  
    }
`

const LoginItemContainer = styled(Box)`
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 50px;
    @media( max-width: 768px){
      margin-top: 40px;
    }
`
const EmailLabel = styled.label`
    font-family: 'Noto Sans CJK KR';
    font-weight: 500;
    font-size:1.125rem;
    color:#333;
    line-height: 1.625rem;
    margin-bottom: 5px;
    @media( max-width: 768px){
      font-size: 1.125rem;
    }
`

const EmailControl = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: ${props => (props?.error ? '2px solid #D40000': '1px solid #A7A7A7')};
  height: 40px;
`

const EmailIcon = styled(FontAwesomeIcon).attrs({color: '#000', icon: faUser, size:'lg'})`
  margin: auto auto;
`

const Exclamation = styled(FontAwesomeIcon).attrs({color: '#D40000', icon: faExclamationCircle})`
  margin: auto auto;
`

const EmailInput = styled.input.attrs({type: 'email', autocomplete: true})`
  font-family: 'Noto Sans CJK KR';
  font-size: 1.125rem;
  padding: 0.5rem 1.5rem 0.5rem 0;
  border-width: 0;
  flex: 1;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
  ::-webkit-input-placeholder {
    color: #C2C3C5;
  }
`

const Button = styled.button`
  width: 100%;
  color: white;
  background-color: #0258CC;
  border-width: 0px;
  border-radius: 2px;
  font-family: 'Noto Sans CJK KR';
  font-size: 1.313rem;
  font-weight: bold;
  line-height: 1.938rem;
  height: 60px;
  cursor: pointer;
  margin: 40px 0 22px 0;
  @media( max-width: 768px){
    margin: 25px 0 15px 0;
    height: 52px;
    font-size: 1.200rem;
  }
  &:hover {
    outline: none;
    background-color: #0058B0;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
    background-color: #0056A8;
  }
`;

const SecondaryBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #555;
  background-color: #F6F6F6;

  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 2px;

  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.500rem;
  
  height: 60px;
  cursor: pointer;
  
  margin-bottom: 10px;
  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
  @media( max-width: 768px){
    height: 52px;
    font-size: 0.933rem;
  }
`;

const CancelBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  background: #FFFFFF;
  border: 1px solid #0258CC;
  box-sizing: border-box;
  border-radius: 2px;

  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.500rem;
  color: #0258CC;
  
  height: 60px;
  cursor: pointer;
  
  margin-bottom: 10px;
  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
  @media( max-width: 768px){
    height: 52px;
    font-size: 0.933rem;
  }
`;

const QRIcon = styled(Box)`
  display: flex;
  width:22px;
  height:22px;
  margin-right: 12px;
  background: center / cover no-repeat url("/img/QR_ICON.png");
  @media( max-width: 768px){
    width:18px;
    height:18px;
  }
`

const OTPIcon = styled(Box)`
  display: flex;
  width:34px;
  height:23px;
  margin-right: 10px;
  background: center / cover no-repeat url("/img/OTP_ICON.png");
`

const TOTPIcon = styled(Box)`
  display: flex;
  width:26px;
  height:26px;
  margin-right: 10px;
  background: center / cover no-repeat url("/img/TOTP_ICON.png");
`

const ExclamationIcon = styled.img.attrs({src: '/img/ExclamationIcon.svg'})`
  width: 55px;
  height: 55px;
  margin-bottom: 25px;
`
const RememberView = styled.div`
  display: flex;
  flex-direction: row;
`;

const RememberText = styled.span`
  font-family: 'Noto Sans CJK KR';
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.688rem;
  color: #7D7D7D;
  cursor: pointer;
  margin-left: 8px;
  @media( max-width: 768px){
    font-size: 1rem;
  }
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props?.type === 'QR' ? 'center' : 'space-between')};
  
  border : ${props => (props?.type === 'QR' ? '1px solid #dedede' : 'none')};
  flex : ${props => (props?.type === 'QR' ? '1' : 'initial')};
  margin-bottom : ${props => (props?.type === 'QR' ? '10px' : '0')};
`

const LoadingContainer = styled(Box)`
  position: absolute;
  width: 100%;
  //height: 100%;
  height: 460px;
  background: ${props => (props?.type === 'LOGIN' ? '#fffffff2' : '#ffffff')};
  //display: flex;
  flex:1;
  flex-direction: column;
  justify-content: space-between;

  display: ${props => (props?.show ? 'flex' : 'none')};
  
  top:-30px;

  @media( max-width: 768px){
    height: 100%;
    margin-top: 30px;
  }
`

const RemainText = styled.label`
  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.500rem;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.04em;

  color: #0258CC;
  
  //margin-top:25px;
`
const ErrorText = styled.label`
  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.500rem;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.04em;

  color: #D40000;

  //margin-top:25px;  
`

const QrTitle = styled.label`
  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.04em;

  color: #555555;
`
const QrSub  = styled.label`

  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  
  margin:10px 0 20px 0;

  color: #888888;

`

const ErrorContainer = styled.div`
  position:absolute;
  width: 100%;
  height:368px;
  
  background: #ffffffe6;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props?.type === 'QR' ? 'center' : 'space-between')};
  
  border : ${props => (props?.type === 'QR' ? '1px solid #dedede' : 'none')};
  flex : ${props => (props?.type === 'QR' ? '1' : 'initial')};
  margin-bottom : ${props => (props?.type === 'QR' ? '10px' : '0')};
`

/* ====================================================================================================== AS-IS ====================================================================================================== */

const ErrorBox = styled.label`
  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem;
  line-height: 1.375rem;
  letter-spacing: -0.04em;
  margin-bottom: 5px;
  
  color: #D40000;
`

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: 100,
        marginBottom:20,
        alignSelf:'flex-end',
        '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:after': {
            border:'none'
        },
        '& > .MuiInputBase-root > .MuiSelect-icon':{
            right:5
        },
        '& > .MuiInputBase-root > .MuiSelect-root':{
            border:'1px solid #C4C4C4',
            boxSizing:'border-box',
            borderRadius: 18,
            paddingLeft:18,
            color: '#0258CC',

            fontFamily: 'Noto Sans CJK KR',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 14,
            lineHeight: '21px',
        },
        '& .MuiSelect-select:focus':{
            backgroundColor: '#fff'
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    options:{
        '& > .MuiPaper-root':{
            background: '#FFFFFF',
            border: '1px solid #C4C4C4',
            boxSizing: 'border-box',
            borderRadius: 18,
            boxShadow:'none',
        },
        '& .MuiList-root > .MuiListItem-button:hover' :{
            backgroundColor:'initial'
        },
        '& .MuiList-root > .MuiButtonBase-root' :{
            fontFamily: 'Noto Sans CJK KR',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: 14,
            lineHeight: '21px',
            color: '#C4C4C4',
        },
        '& .MuiList-root > .MuiButtonBase-root.Mui-selected' :{
            backgroundColor:'#fff',
            color: '#0258CC',
        },
        '& .MuiTouchRipple-root':{
            display:'none'
        }
    },
    otpBtnContainer: {
        display: "flex",
        '& > div': {
            marginRight: 10,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    otpLoginBtn: {
        margin: '20px 0 12px 0 !important'
    },
    otpInput: {
        margin: theme.spacing(1) / 4,
        '& > input' :{
            width: '100% !important',
            height: theme.spacing(8),
            backgroundColor: 'transparent',
            border: '2px solid #E2E2E2',
            borderRadius: theme.spacing(1),
            color: '#247DFF',
            fontSize: '32px',
            fontWeight: '600',
            '@media (max-width: 768px)': {
                width: '1em !important'
            }
        },
        '&:first-child': {
            marginLeft: 0
        },
        '&:last-child': {
            marginRight: 0
        },
        '& > input:focus-visible': {
            outline: 'none',
            border: '2px solid #247DFF'
        }
    },
    otpError: {
        color: '#D40000 !important'
    }
}));


const encodeParams = (data, nonNull = false) => {
    return Object
        .keys(data)
        .map(key => {
            if (!nonNull || data[key] !== null) {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            }
            return null;
            }
        )
        .filter(value => value !== null)
        .join('&');
};


function App(url, config) {
    const [cookies, setCookie, removeCookie] = useCookies(['rememberEmail']);
    const [remainCookies, setRemainCookie, removeRemainCookie] = useCookies(['authContinue']);
    const [email, setEmail] = useState(null);
    const [open, setOpen] = useState({show: false, type:''});
    const [validation, setValidation] = useState({show: false, message: ""});
    const [error, setError] = useState({show: false, message: ""});
    const [visible, isVisible] = useState(false);
    const [countDown, setCountDown] = useState(null);
    const fncClearInterval = useRef(null);
    const [qrData, setQrData] = useState({id:null, url:null});
    const [qrUserKey, setQrUserKey] = useState(null);
    const [state, setState] = useState({ checked: false })
    const [lang, setLang] = useState('en');
    const [otpCode, setOtpCode] = useState(null);
    const [otpErr, setOtpErr] = useState(false);
    const [otpOpen, setOtpOpen] = useState(false);
    const [totpOpen, setTotpOpen] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const otpUserKey = useRef(null);
    const stompConnector = useRef(null);
    const isFinished = useRef(false);

    const qr_userKey = useRef(null);
    const qr_channelKey = useRef(null);

    const theme = useTheme();
    const isNotMobile = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles();
    const otpDigit = 6;

    const handleCheckboxChange = ({target}) => {
        setState({ checked: target.checked });
    }

    const customInterval = (time, onClose, timeoutMsg = null) => {
        let count = time;

        const id = setInterval(() => {
            count = count - 1;
            if (count) {
                setCountDown(count);
            } else {
                clear(true);
            }
        }, 1000);


        const clear = (isTimeout) => {
            if (id !== null) {

                if( stompConnector.current !== null ) {
                    console.log("stompConnector.current.deactivate();");
                    stompConnector.current.deactivate();
                }

                clearInterval(id);
                if (isTimeout && timeoutMsg) {
                    //setOpen({show:false});
                    isFinished.current = true;
                    isVisible(false);
                    setError({show:true, message: timeoutMsg});
                    removeRemainCookie('authContinue');
                }
                if (onClose) {
                    onClose();
                }
                setCountDown(null);
            }
        }

        return () => {
            clear(false)
        };
    }

    useEffect(() => {
        if (cookies.rememberMe !== undefined) {
            setEmail(cookies.rememberMe)
            setState({ checked: true });
        }

        if( remainCookies.authContinue !== undefined ) {
            console.log("remainCookies.authContinue ? ",remainCookies.authContinue);
            setOpen({show:true, type: 'LOGIN'});
            const startTime = remainCookies.authContinue.startTime;
            const currentTime = Date.now();

            remainCookies.authContinue.authTimeRemaining = Math.floor( (remainCookies.authContinue.authTimeRemaining - (currentTime - startTime)) / 1000) * 1000;

            if( remainCookies.authContinue.authTimeRemaining > 0 ) {
                doConnectWS(remainCookies.authContinue.query, remainCookies.authContinue.authTimeRemaining, 'LOGIN');
            } else {
                removeRemainCookie('authContinue');
            }
        }
    }, []);

    const doMailHostLogin = (token, userKey = null) => {
        if (!MAIL_HOST) {
            alert("dev success");
            setIsLoggingIn(false); // Enable the login button again
            return;
        }

        userKey && removeRemainCookie('authContinue');

        axios.post(
            `${MAIL_HOST}/login`,
            {token}
        ).then(res => {
            isVisible(false);
            const {redirectURL} = res.data;
            if (redirectURL) {
                if (userKey) {
                    if (state.checked) {
                        if (!otpUserKey.current) {
                            setCookie('rememberMe', email, {maxAge: 3600 * 24 * 3})
                        }
                    } else {
                        removeCookie('rememberMe')
                    }
                }
                window.location.href = res.data.redirectURL;
            } else {
                alert(i18next.t("Error.undefined"));
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsLoggingIn(false); // Enable the login button again
        });
    }

    const doLogin = ({isOtpAuth = false}) => {
        if (isLoggingIn) return; // Prevent multiple clicks
        setIsLoggingIn(true); // Disable the login button

        resetInfo();

        let userKey = otpUserKey.current ? otpUserKey.current : email;

        if (!userKey || userKey.length === 0) {
            setValidation({
                show: true,
                message: i18next.t("Validation.Email")
            });
            setIsLoggingIn(false); // Enable the login button again
            return;
        }

        if (state.checked && !otpUserKey.current) {
            setCookie('rememberMe', email, {maxAge: 3600 * 24 * 3})
        }

        isFinished.current = false;

        GuardianAPI.requestAuth(CLIENT_KEY, userKey, isOtpAuth)
            .then(res => {
                const rtCode = res.rtCode;
                if (rtCode === 0) {
                    const {userKey, channelKey, authTimeRemaining} = res.data;
                    const query = encodeParams({clientKey: CLIENT_KEY, userKey, channelKey});

                    if (remainCookies.authContinue === undefined) {
                        const params = {query: query, authTimeRemaining: authTimeRemaining, startTime: Date.now()};
                        setCookie('authContinue', params, {maxAge: authTimeRemaining / 1000})
                    }

                    doConnectWS(query, authTimeRemaining, "LOGIN", isOtpAuth);
                }
            })
            .catch(err => {
                console.log(err);
                if (fncClearInterval.current != null) { fncClearInterval.current(); }
                setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
                setIsLoggingIn(false); // Enable the login button again
            });
    };

    const doConnectWS = (query, authTimeRemaining, type, isOtpAuth = false) => {
        let userKey = otpUserKey.current ? otpUserKey.current : email;

        fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1, null,i18next.t("AuthStatus.LoginTimeout"));
        setOpen({show:true, type: type});

        isVisible(true);

        stompConnector.current = GuardianAPI.getStompWS(query);

        stompConnector.current.onConnect = function( data ) {
            const onClose = () => {
                subscribeStomp.unsubscribe();
                fncClearInterval.current();
            }

            const subscribeCallBack = ( message ) => {
                const data = JSON.parse(message.body);
                const authStatus = data.status;

                if( authStatus === "AuthFailed" || authStatus === "AuthCanceled" || authStatus === "AuthCompleted" ) {

                    isFinished.current = true;

                    if (authStatus === "AuthCompleted") {
                        onClose();

                        if (isOtpAuth) {
                            setOpen({show:false});
                            setOtpOpen(true);
                        } else {
                            GuardianAPI.getAuthResult(query)
                                .then(res => {
                                    if( res.rtCode === 0 ) {
                                        doMailHostLogin(res.data.accessToken, userKey);
                                    }
                                })
                                .catch(err => {
                                    console.log("getAuthResult error", err);
                                })
                        }
                    } else {
                        switch (authStatus) {
                            case "AuthFailed":
                            case "AuthCanceled":
                                setError({show: true, message: i18next.t("AuthStatus."+authStatus)});
                                isVisible(false);
                                onClose();
                                setIsLoggingIn(false); // Enable the login button again
                                break;
                        }
                    }
                }
            }

            const subscribeStomp = stompConnector.current.subscribe('/user/queue', function(message) {
                if (message.command === "MESSAGE") {
                    subscribeCallBack(message);
                } else {
                    setError({show: true, message: i18next.t("AuthStatus.AuthFailed")});
                    setIsLoggingIn(false); // Enable the login button again
                    return ;
                }

            });
        };

        stompConnector.current.onWebSocketClose = () => {
            console.log(" Close Web Socket Event Test");

            if( !isFinished.current ) {

                GuardianAPI.getAuthResult(query)
                    .then(res => {

                        if( res.rtCode === 0 ) {
                            doMailHostLogin(res.data.accessToken, userKey);
                        } else {
                            if( res?.rtCode !== 2010 ) {
                                // parentRef.current?.setErrMsg(api.getErrMsg(data.rtCode));
                                // parentRef.current?.setProgressMsg(null);
                                setError({show: true, message: i18next.t("AuthStatus.AuthFailed")});
                            }
                        }

                    })
                    .catch(err => {
                        console.log("getAuthResult error", err);
                        setIsLoggingIn(false); // Enable the login button again
                    })

            }

        }

        stompConnector.current.onDisconnect = () => {
            console.log("close connect event test");
        }


    }

    const doCancel = () => {
        isFinished.current = true;

        if (error.show) {
            setOpen({show:false});
            return;
        }

        if( fncClearInterval.current() != null ) { fncClearInterval.current(); }

        if( remainCookies.authContinue !== undefined ) { removeRemainCookie('authContinue'); }

        resetInfo();

        let userKey = otpUserKey.current ? otpUserKey.current : email;

        GuardianAPI.deleteAuth(CLIENT_KEY, userKey, null)
            .then(res => {
                const rtCode = res.rtCode;
                if( rtCode === 0 ) {
                    setError({show: true, message: i18next.t("AuthStatus.AuthCanceled")});
                }
            })
            .catch(err => {
                setError({show: true, message: err.rtMsg});
            })
        setIsLoggingIn(false);
    }

    const onEnter = (event) => {
        if( event.key === 'Enter') {
            doLogin({isOtpAuth : false});
        }
    }

    const onQrLogin = (event) => {
        resetInfo();
        GuardianAPI.getLoginQrUrl(CLIENT_KEY)
            .then(res => {
                if (res.rtCode === 0) {
                    isFinished.current = false;

                    const QR_DATA = res.data;
                    setQrData({id: QR_DATA.qrId, url: QR_DATA.qrUrl});

                    setOpen({show: true, type: 'QR'});

                    const authTimeRemaining = 60000;
                    // const authTimeRemaining = 1000;

                    stompConnector.current = GuardianAPI.getStompQrWS(QR_DATA.qrId);

                    // 타이머 시작
                    fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1, null, i18next.t("AuthStatus.LoginTimeout"));

                    stompConnector.current.onConnect = (data) => {

                        const onClose = () => {
                            subscribeStomp.unsubscribe();
                            fncClearInterval.current();
                        }

                        const subscribeCallBack = (message) => {
                            const data = JSON.parse(message.body);
                            const authStatus = data.status;
                            const type = data.type;

                            if (type !== undefined && type === "data") {
                                qr_userKey.current = data.userKey;
                                qr_channelKey.current = data.channelKey;
                            }


                            if (authStatus == "RequestAuth") { setQrUserKey(data.userKey); }


                            if( authStatus === "AuthFailed" || authStatus === "AuthCanceled" || authStatus === "AuthCompleted" ) {

                                isFinished.current = true;

                                if (authStatus === "AuthCompleted") {
                                    onClose();
                                    GuardianAPI.getAuthResult(encodeParams({
                                        clientKey : CLIENT_KEY,
                                        userKey   : qr_userKey.current,
                                        channelKey: qr_channelKey.current
                                    }))
                                        .then(res => {
                                            setOpen({show: false});
                                            doMailHostLogin(res.data.accessToken);
                                        }).catch(err => {
                                        console.log("getAuthResult error", err);
                                    });

                                } else {
                                    switch (authStatus) {
                                        case "AuthFailed":
                                        case "AuthCanceled":
                                            setError({show: true, message: i18next.t("AuthStatus." + authStatus)});
                                            isVisible(false);
                                            onClose();
                                            break;
                                    }
                                }
                            }
                        }

                        const subscribeStomp = stompConnector.current.subscribe('/user/queue', function(message){
                            if( message.command === "MESSAGE" ) {
                                subscribeCallBack(message);
                            } else {
                                setError({show: true, message: i18next.t("AuthStatus.AuthFailed")});
                            }
                        });

                    }

                    stompConnector.current.onWebSocketClose = () => {
                        console.log(" Close Web Socket Event Test");

                        if( !isFinished.current ) {
                            GuardianAPI.getAuthResult(encodeParams({
                                clientKey : CLIENT_KEY,
                                userKey   : qr_userKey.current,
                                channelKey: qr_channelKey.current
                            }))
                                .then(res => {
                                    if (res.rtCode === 0) {
                                        setOpen({show: false});
                                        doMailHostLogin(res.data.accessToken);
                                    } else {

                                    }
                                }).catch(err => {
                                console.log("getAuthResult error", err);
                            });
                        }
                    }

                    stompConnector.current.onDisconnect = () => {
                        console.log("close connect event test");
                    }

                }
            }).catch(err => {
                console.log("getAuthResult error", err);
            })

    }

    const doQrCancel = (event) => {
        setOpen({show:false});

        if( fncClearInterval.current !== null ) { fncClearInterval.current(); }

        if (error.show) {
            return;
        }

        GuardianAPI.deleteAuth(CLIENT_KEY, qrUserKey, qrData.id)
            .then(res => {
                const rtCode = res.rtCode;
                if( rtCode === 0 ) {
                    setError({show: true, message: i18next.t("AuthStatus.AuthCanceled")});
                }
            }).catch(err => {
            console.log(err);
            setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
        });
        setQrUserKey(null);
    }

    const doOTPLogin = (event) => {
        if (!otpCode || otpCode.length !== otpDigit) {
            setValidation({
                show: true,
                message: i18next.t("Validation.Otp")
            });
            setOtpErr(true);
            return;
        }

        resetInfo();

        if (otpOpen) {
            if (email) {
                GuardianAPI.postOtpUserVerify(CLIENT_KEY, email, otpCode)
                    .then(async res => {
                        const rtCode = res.rtCode;
                        if (rtCode === 0) {
                            setOpen({show:true, type: 'OTP_LOGIN'});
                            if (state.checked) {
                                setCookie('rememberMe', email, {maxAge: 3600 * 24 * 3})
                            } else {
                                removeCookie('rememberMe')
                            }
                            await timeout(1000);
                            doMailHostLogin(res.data.accessToken, email);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
                        setOtpErr(true);
                    })
            } else {
                GuardianAPI.getOtpVerify(CLIENT_KEY, otpCode)
                    .then(res => {
                        const rtCode = res.rtCode;
                        if (rtCode === 0) {
                            otpUserKey.current = res.data;
                            doLogin({isOtpAuth : false});
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
                        setOtpErr(true);
                    })
            }
        } else if (totpOpen) {

            GuardianAPI.postTotpUserVerify(CLIENT_KEY,email, otpCode)
                .then(async res => {
                    const rtCode = res.rtCode;
                    if (rtCode === 0) {
                        setOpen({show: true, type: 'OTP_LOGIN'});
                        await timeout(1000);
                        doMailHostLogin(res.data.accessToken, email);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
                    setOtpErr(true);
                })
        }
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const setLanguage = ( language ) => {
        if( language !== undefined && language !== null ){
            i18next.changeLanguage(language);
        }
        setLang(language);
    }

    const onLanguageChange = (event) => {
        setLanguage(event.target.value);
    }

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const onOtpCodeChange = (value) => {
        setOtpErr(false);
        setOtpCode(value);
    }

    const onOtpOpen = () => {
        if (email) {
            doLogin({isOtpAuth : true});
        } else {
            resetInfo();
            setOtpOpen(true);
        }
    }

    const onTotpOpen = () => {
        resetInfo();
        if (!email || email.length === 0) {
            setValidation({
                show: true,
                message: i18next.t("Validation.Email")
            });
            return;
        }
        setTotpOpen(true);
    }

    const onOtpClose = () => {
        resetInfo();
        setOtpOpen(false);
        setTotpOpen(false);
        setOtpCode(null);
        setIsLoggingIn(false);
    }

    const resetInfo = () => {
        setValidation({show: false});
        setError({show:false});
        isVisible(false);
        setOpen({show:false});
        setOtpErr(false);
    }

    return (
        <FullView>

            <LoginContainer>
                <LoginImageBox>
                    <LoginAnimated/>
                </LoginImageBox>
                <LoginBox>

                    <FormControl className={classes.formControl}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={lang}
                            onChange={onLanguageChange}
                            MenuProps={{className:classes.options}}
                        >
                            <MenuItem value={'en'}>EN</MenuItem>
                            <MenuItem value={'ko'}>KR</MenuItem>
                        </Select>
                    </FormControl>

                    <LoginLabel>{i18next.t('Login.Title')}</LoginLabel>
                    <LoginText>LOGIN</LoginText>
                    <LoginItemContainer>

                        {validation.show
                            ? <ErrorBox>{validation.message}</ErrorBox>
                            : <EmailLabel>{otpOpen ? 'OTP Authentication' : totpOpen ? 'TOTP Authentication' : 'Username'}</EmailLabel>}

                        {otpOpen || totpOpen ?
                            <React.Fragment>
                                <Box onKeyDown={(e) => {e.key === 'Enter' && doOTPLogin(e)}}>
                                    <OtpInput
                                        className={classes.otpInput}
                                        value={otpCode}
                                        onChange={onOtpCodeChange}
                                        numInputs={otpDigit}
                                        isInputNum={true}
                                        hasErrored={otpErr}
                                        shouldAutoFocus={true}
                                        errorStyle={classes.otpError}
                                    />
                                </Box>

                                <Button onClick={doOTPLogin} className={classes.otpLoginBtn}>{i18next.t("Login.LoginButton")}</Button>

                                <CancelBox onClick={onOtpClose}>{i18next.t('Login.CancelButton')}</CancelBox>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <EmailControl error={validation.show}>
                                    <EmailInput
                                        value={email || ''}
                                        placeholder={i18next.t('Login.Placeholder')}
                                        onChange={onEmailChange}
                                        onKeyDown={onEnter}
                                        disabled={visible}
                                    />
                                    {validation.show ? <Exclamation style={{width:20}}/> : <EmailIcon/> }
                                </EmailControl>

                                <Button onClick={doLogin} type={"submit"} disabled={isLoggingIn}>{i18next.t("Login.LoginButton")}</Button>

                                <RememberView>
                                    <label>
                                        <Checkbox
                                            checked={state.checked}
                                            onChange={handleCheckboxChange}
                                            // ref={rememberMe}
                                        />
                                        <RememberText>{i18next.t("Login.RememberMe")}</RememberText>
                                    </label>
                                </RememberView>

                                <Box marginTop={'auto'}>
                                    {isNotMobile &&
                                    <SecondaryBox onClick={onQrLogin}>
                                        <QRIcon />
                                        {i18next.t('Login.QrLoginButton')}
                                    </SecondaryBox>}

                                    <Box className={classes.otpBtnContainer}>
                                        <SecondaryBox onClick={onOtpOpen} disabled={isLoggingIn}><OTPIcon /> OTP </SecondaryBox>
                                        <SecondaryBox onClick={onTotpOpen}> <TOTPIcon /> TOTP </SecondaryBox>
                                    </Box>
                                </Box>
                            </React.Fragment>
                        }

                        <LoadingContainer show={open.show} type={open.type}>


                            { open.type === 'LOGIN' && (
                                <React.Fragment>
                                    <ColumnContainer>

                                        { error.show
                                            ? ( <React.Fragment>
                                                    <ExclamationIcon/>
                                                    <ErrorText>{error.message}</ErrorText>
                                                </React.Fragment>)
                                            : ( <React.Fragment>
                                                    <SpinCircle />
                                                    <RemainText>{countDown}{i18next.t('Login.RemainTime')}</RemainText>
                                                </React.Fragment>)
                                        }
                                    </ColumnContainer>
                                    <CancelBox onClick={doCancel}>{i18next.t('Login.CancelButton')}</CancelBox>
                                </React.Fragment>
                            ) }

                            { open.type === 'QR' && (
                                <React.Fragment>
                                    <ColumnContainer type={open.type}>
                                        <QrTitle>{i18next.t('Login.QrTitle')}</QrTitle>
                                        <QrSub>{i18next.t('Login.QrSubTitle')}</QrSub>
                                        <QRCode style={{margin:'10px 0'}}
                                                value={qrData?.url}
                                                size={128}
                                                imageSettings={{
                                                    src: '/img/qr_logo.svg',
                                                    width: 33,
                                                    height: 38,
                                                }}
                                        />
                                        <RemainText>{countDown}{i18next.t('Login.RemainTime')}</RemainText>
                                    </ColumnContainer>

                                    { error.show && (
                                        <ErrorContainer type={open.type}>
                                            <ExclamationIcon/>
                                            <ErrorText>{error.message}</ErrorText>
                                        </ErrorContainer>
                                    ) }

                                    <CancelBox onClick={doQrCancel}>{i18next.t('Login.CancelButton')}</CancelBox>
                                </React.Fragment>
                            ) }

                            { open.type === 'OTP_LOGIN' && (
                                <React.Fragment>
                                    <ColumnContainer>
                                        <SpinCircle />
                                    </ColumnContainer>
                                </React.Fragment>
                            )}

                        </LoadingContainer>
                    </LoginItemContainer>

                </LoginBox>
            </LoginContainer>

        </FullView>
    );
}

export default App;
