import i18next from "i18next";
import i18nextHttpBackend from 'i18next-http-backend';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

const translation_en = require('../locales/en/translation.json');
const translation_ko = require('../locales/ko/translation.json');

i18next
    .use(i18nextHttpBackend)
    .use(i18nextBrowserLanguageDetector)
    .init({
        lng: "en",
        fallbackLng: 'en',
        debug: false,
        resources: {
            en: { translation: {...translation_en} },
            ko: { translation: {...translation_ko} }
        }
    });

export default i18next;
