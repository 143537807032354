import axios from 'axios';
import i18next from "i18next";
import SockJS from 'sockjs-client';
import * as StompJS from "@stomp/stompjs";

class GuardianAPI {
    GUARDIAN_HOST = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "https://lab.fnsvalue.co.kr" : "https://lab.fnsvalue.co.kr";
    api = null;

    constructor() {
        this.api = axios.create({
            baseURL: this.GUARDIAN_HOST + '/api/v3',
            timeout: 1000 * 30,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {}
        });
        this.api.defaults.withCredentials = true;
        this.api.interceptors.request.use(config => {
            config.metadata = {startTime: new Date()};
            return config;
        });
        this.api.interceptors.response.use(response => {
            const {rtCode} = response.data;
            if (rtCode !== 0) {
                response.data.rtMsg = this.getErrMsg(rtCode);
                throw response.data;
            }

            return response;
        });
    }

    getErrMsg = (errCode) => {
        return i18next.t('Error.' + errCode);
    };

    encodeParams = (data, nonNull = false) => {
        return Object
            .keys(data)
            .map(key => {
                if (!nonNull || data[key] !== null) {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
                }
                return null;
                }
            )
            .filter(value => value !== null)
            .join('&');
    };

    getWebSocket = (query) => {
        const ws = new SockJS(this.GUARDIAN_HOST + '/ws/v3/auth/status?' + query);
        return ws;
    };

    getQrWebSocket = (qrId) => {
        const query = this.encodeParams({qrId});
        const ws = new SockJS(this.GUARDIAN_HOST + '/ws/v3/qr/status?' + query);
        return ws;
    };

    getStompHOST = () => { return this.GUARDIAN_HOST.replace("http","ws"); };

    getStompWS = (query) => {
        const stomp = new StompJS.Client({
            brokerURL: this.getStompHOST() + '/ws/v3/app/websocket?' + query
            // ,debug: function (data){ console.log("debug => ", data); }
            , reconnectDelay: 500
        })
        stomp.activate();
        return stomp;
    };

    getStompQrWS = (qrId) => {
        const query = this.encodeParams({qrId});
        const stomp = new StompJS.Client({
            brokerURL: this.getStompHOST() + '/ws/v3/app/qr/websocket?' + query
            // ,debug: function (data){ console.log("debug => ", data); }
            , reconnectDelay: 500
        })
        stomp.activate();
        return stomp;
    }


    requestAuth = ( clientKey, userKey, isOtpAuth = false ) => {
        return new Promise((resolve, reject) => {
            this.api.post('/auth', {clientKey, userKey, isOtpAuth}, {withCredentials : true})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getAuthResult = (query) => {
        return new Promise((resolve, reject) => {
            this.api.get('/auth?' + query)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    deleteAuth = (clientKey, userKey, qrId) => {
        return new Promise((resolve, reject) => {
            this.api.delete('/auth', {data: {clientKey: clientKey, userKey: userKey, qrId: qrId}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getLoginQrUrl = (clientKey) => {
        return new Promise((resolve, reject) => {
            const query = this.encodeParams({clientKey});
            this.api.get('/qr/generate?' + query)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    };

    postTotpUserVerify = (clientKey, userKey, otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.post('/totp/user/verify', {clientKey, userKey, otpCode})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    };

    getOtpVerify = (clientKey, otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/otp/verify?${this.encodeParams({clientKey, otpCode})}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    };

    postOtpUserVerify = (clientKey, userKey, otpCode) => {
        return new Promise((resolve, reject) => {
            this.api.post('/otp/user/verify', {clientKey, userKey, otpCode})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    };
}

export default new GuardianAPI();
